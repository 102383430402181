<template>
  <BasePage :isLoaded="!!Packaging">
    <template v-slot:content>
      <v-container class="packaging-page-container">
        <v-row ref="contentContainer" class="mb-16 pt-16">
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <div ref="gallery" class="gallery-container">
              <Cta
                v-if="!!Packaging._referents.CollectionReferents.items.length"
                :to="{ name: 'CollectionPage', params: { slug: Packaging._referents.CollectionReferents.items[0]._slug } }"
                :reverse="true"
                action="internal"
                label="View Collection"
                class="d-block mb-4"
              />
              <Gallery
                :slides="Packaging.gallery"
                :slideCaption="false"
                :slidesPerView="1"
                class="pa-0"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4" offset-md="1">
            <h2 class="h2 -sci-gradient">{{ Packaging.title }}</h2>
            <p>{{ Packaging.subTitle }}</p>
            <MarkdownBlock
              :text="Packaging.description"
              :inline="false"
              tag="div"
              class="Packaging-section-content"
            />
            <PackagingModularContent
              v-if="!!Packaging.modularContent"
              :contents="Packaging.modularContent"
              :colors="Packaging.colors"
            />
          </v-col>
        </v-row>
      </v-container>
      <PackagingVideoTutorial
        v-if="!!(Packaging.videoTutorial || Packaging.videoFileTutorial)"
        :video="(Packaging.videoTutorial || Packaging.videoFileTutorial)"
        :poster="Packaging.videoFileTutorialPoster ? Packaging.videoFileTutorialPoster.id : null"
        title="Packaging Review"
        subtitle="Our Expert’s portrait of this packaging"
      />
      <CardCarousel
        v-if="!!Packaging.similarPackagings"
        :slides="Packaging.similarPackagings"
        title="Similar Packaging"
      />
    </template>
  </BasePage>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import GetPackaging from '@/graphql/GetPackaging.gql'

import BasePage from '@/organisms/BasePage'
import Gallery from '@/molecules/Gallery.vue'
import CardCarousel from '@/molecules/CardCarousel.vue'

import PackagingModularContent from '@/molecules/ModularContent.vue'
import PackagingVideoTutorial from '@/molecules/modular_content/ModularVideoTutorial.vue'
import Cta from '@/molecules/Cta.vue'

export default {
  name: 'PackagingPage',

  apollo: {
    Packaging: {
      query: GetPackaging,
      variables () {
        return {
          slug: this.slug
        }
      },
      async result (res) {
        if (!res.data.Packaging) {
          this.$router.push({ name: 'Home' })
        }
      }
    }
  },
  components: {
    MarkdownBlock,
    BasePage,
    Gallery,
    CardCarousel,
    PackagingModularContent,
    PackagingVideoTutorial,
    Cta
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  watch: {
    Packaging () {
      this.$nextTick(() => {
        this.stickySection()
      })
    }
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    stickySection () {
      ScrollTrigger.matchMedia({
        // desktop
        '(min-width: 960px)': this.desktopTimeline
      })
    },
    desktopTimeline () {
      this.tl = ScrollTrigger.create({
        pin: this.$refs.gallery,
        trigger: this.$refs.contentContainer,
        start: () => {
          const bb = this.$refs.contentContainer.getBoundingClientRect()
          return `top +=${bb.top}`
        },
        end: () => {
          const bbC = this.$refs.contentContainer.getBoundingClientRect()
          const bb = this.$refs.gallery.getBoundingClientRect()
          return `+=${bbC.height - bb.height}`
        },
        pinSpacing: false
      })
      return this.tl
    }
  },
  metaInfo: function () {
    if (this.Packaging) {
      this.$events.$emit('global-breadcrumbs', [
        {
          name: 'ProductsShowcase',
          label: 'Virtual Lab'
        },
        {
          name: 'CollectionPage',
          label: this.Packaging._referents.CollectionReferents.items[0].title,
          params: {
            slug: this.Packaging._referents.CollectionReferents.items[0]._slug
          }
        }
      ])

      return {
        title: this.Packaging.title,
        meta: [
          { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Packaging.title },
          { vmid: 'description', name: 'description', content: this.Packaging.subTitle },
          { vmid: 'ogdescription', property: 'og:description', content: this.Packaging.subTitle },
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/media/' + this.Packaging.gallery[0] ? this.Packaging.gallery[0].id : '' }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.packaging-page-container {
  position: relative;
}

h2 {
  margin-bottom: rem(12px);

  @include breakpoint ('sm-and-down') {
    margin-top: rem(57px);
  }
}

.Packaging-section-content {
  margin: rem(36px) 0;
}
</style>
